import Vue from 'vue'

/**
 * The default proto name
 */
export let name = 'toast'

/**
 * The default container name
 */
export let containerName = 'toaster'

/**
 * The default type-shortcuts
 */
export let types = [
  'success',
  'error',
  'info',
  'log',
  'warning',
]

/**
 * A Vue instance for emitting events.
 */
export let events = new Vue

/**
 * Default options to pass to the toaster.
 */
export let defaults = {
  position: ['bottom', 'right'],
  easing: {
    enter: 'easeOutSine',
    leave: 'easeInSine'
  },
  closeButton: false,
  animation: {
    enter: element => {
      let height = element.clientHeight
      return {
        height: [height, 0],
        opacity: [1, 0]
      }
    },
    leave: {
      height: 0,
      opacity: [0, 1]
    }
  }
}

<template>
  <transition-group @enter="enter" @leave="leave" @after-leave="afterLeave">
    <slot/>
  </transition-group>
</template>

<script>
export default {

  name: 'Tray',

  methods: {
    enter(element, complete) {
      this.$emit('enter', { element, complete })
    },
    leave(element, complete) {
      this.$emit('leave', { element, complete })
    },
    afterLeave() {
      this.$emit('afterLeave')
    }
  }

}
</script>

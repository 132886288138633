import Toaster from './components/Toaster.vue'
import toast from './utility'
import { proto } from './helpers'
import { types, name, containerName } from './config'

export default {
  toast,
  types,

  install(Vue, options = {}) {

    // Pre-flight check
    if (this.installed) {
      throw 'Toast is installed already.'
    }

    // Install the toaster (container)
    Vue.component(options.containerName || containerName, Toaster)

    // Install into Vue
    proto(Vue, options.name || name, toast)

    // Install handy types
    let types = options.types || types
    types.forEach(type => {
      proto(Vue, type, (left, right = '') => toast({
        type,
        text: right || left,
        title: right ? left : '',
        group: options.group || ''
      }), options)
    });

    this.installed = true
  }
}

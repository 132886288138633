let getType = value => {
  let floatExpression = '[-+]?[0-9]*.?[0-9]+',
    types = [
      { name: 'px', expression: new RegExp(`^${floatExpression}px$`) },
      { name: '%', expression: new RegExp(`^${floatExpression}%$`) },
      { name: 'px', expression: new RegExp(`^${floatExpression}$`) } // fallback
    ]

  if (value === 'auto') {
    return {
      type: value,
      value: 0
    }
  }

  for (let type of types) {
    if (type.expression.test(value)) {
      return {
        type: type.name,
        value: parseFloat(value)
      }
    }
  }

  return { type: '', value }
}

export default value => {
  let types = {
    number: () => ({ type: 'px', value }),
    string: () => getType(value),
    default: () => ({ type: '', value })
  }

  return (types[typeof value] || types['default'])()
}

import { events } from './config'

export default params => {
  if (typeof params === 'string') {
    params = { title: '', text: params }
  }
  if (typeof params === 'object') {
    events.$emit('make', params)
  }
}

<template>
  <div class="toaster" v-bind="{ style }">
    <tray @enter="enter" @leave="leave" @after-leave="clean">
      <div v-if="toast.state !== 2" v-for="toast in list" :key="toast.id" class="toast-wrapper" :data-id="toast.id">
        <slot name="toast" :class="[classes, toast.type]" v-bind="{ toast }" :close="() => close(toast)">
          <!-- Default slot template -->
          <div :class="toastClass(toast)" @click="close(toast)">
            <div class="toast-main">
              <div v-if="toast.title" class="toast-title" v-html="toast.title" />
              <div class="toast-content" v-html="toast.text" />
            </div>
            <a v-if="toast.closeButton || closeButton" @click="close(toast)" :class="toast.closeButton || closeButton"></a>
          </div>
        </slot>
      </div>
    </tray>
  </div>
</template>

<script>
// Tools
import { id, split, listToDirection } from '../helpers'
import { defaults, events } from '../config'
import parse from '../parser'
import state from '../state'

// External
import velocity from 'velocity-animate'

// Components
import Tray from './Tray.vue'

export default {

  name: 'Toaster',

  components: {
    Tray,
  },

  props: {
    group: {
      type: String,
      default: ''
    },
    width: {
      type: Number|String,
      default: 375
    },
    closeButton: {
      type: Boolean|String,
      default: () => defaults.closeButton
    },
    reverse: {
      type: Boolean,
      default: false
    },
    position: {
      type: Array|String,
      default: () => defaults.position
    },
    classes: {
      type: String,
      default: 'toast'
    },
    animation: {
      type: Object,
      default: () => defaults.animation
    },
    easeEnter: {
      type: String,
      default: () => defaults.easing.enter
    },
    easeLeave: {
      type: String,
      default: () => defaults.easing.leave
    },
    speed: {
      type: Number,
      default: 375
    },
    duration: {
      type: Number,
      default: 4000
    },
    delay: {
      type: Number,
      default: 100
    },
    max: {
      type: Number,
      default: 3
    },
    callback: {
      type: Boolean|Function,
      default: false
    },
  },

  data: () => ({
    list: [],
  }),

  mounted() {
    events.$on('make', this.makeToast);
  },

  computed: {
    actualWidth() {
      return parse(this.width)
    },

    style() {
      let { x, y } = listToDirection(this.position),
        width = this.actualWidth.value,
        suffix = this.actualWidth.type

      let styles = {
        width: width + suffix,
        [y]: '0px'
      }

      if (x === 'center') {
        styles['left'] = `calc(50% - ${width / 2}${suffix})`
      } else {
        styles[x] = '0px'
      }

      return styles
    },

    active() {
      return this.list.filter(item => item.state !== state.destroyed)
    },

    toTop() {
      return this.style.hasOwnProperty('bottom')
    }
  },

  methods: {
    makeToast(event) {
      event.group = event.group || ''

      if (this.group !== event.group) return

      if (event.clean || event.clear) {
        this.destroyAll()
        return
      }

      let duration = typeof event.duration === 'number'
        ? event.duration
        : this.duration

      let speed = typeof event.speed === 'number'
        ? event.speed
        : this.speed

      let callback = typeof event.callback === 'function'
        ? event.callback
        : this.callback

      let { title, text, type, data, closeButton } = event

      let toast = {
        id: id(),
        title,
        text,
        type,
        state: state.idle,
        speed,
        length: duration + 2 * speed,
        data,
        callback,
        closeButton
      }

      if (duration >= 0) {
        toast.timer = setTimeout(() => {
          this.destroy(toast)
        }, toast.length)
      }

      let direction = this.reverse
        ? !this.toTop
        : this.toTop

      let indexToDestroy = -1

      if (direction) {
        this.list.push(toast)
        if (this.active.length > this.max) indexToDestroy = 0
      } else {
        this.list.unshift(toast)
        if (this.active.length > this.max) indexToDestroy = this.active.length - 1
      }

      if (indexToDestroy !== -1) this.destroy(this.active[indexToDestroy])
    },

    toastClass(toast) {
      return [
        'toast',
        this.classes,
        toast.type
      ]
    },

    close(toast) {
      if (toast.callback) {
        toast.callback(toast, () => this.destroy(toast))
      } else {
        this.destroy(toast)
      }
    },

    destroy(toast) {
      clearTimeout(toast.timer)
      toast.state = state.destroyed
    },

    destroyAll() {
      this.active.forEach(this.destroy)
    },

    getAnimation(index, element) {
      let animation = this.animation[index]
      return typeof animation === 'function'
        ? animation.call(this, element)
        : animation
    },

    enter({ element, complete }) {
      let animation = this.getAnimation('enter', element)
      velocity(element, animation, {
        duration: this.speed,
        easing: this.easeEnter,
        complete
      })
    },

    leave({ element, complete }) {
      let animation = this.getAnimation('leave', element)
      velocity(element, animation, {
        duration: this.speed,
        easing: this.easeLeave,
        complete
      })
    },

    clean() {
      this.list = this.list.filter(v => v.state !== state.destroyed)
    }
  }
}
</script>

import { name } from './config'

/**
 * Prototype installer
 */
export let proto = (Vue, key, proto, options = {}) => {
  if (options.namespaced) {
    let rootKey = `$${options.name || name}`
    if (!Vue.hasOwnProperty(rootKey) || !Vue.prototype.hasOwnProperty(rootKey)) {
      throw 'The Toast root prototype hasn’t been set.'
    }

    Vue[rootKey][key] = proto
    Vue.prototype[rootKey][key] = proto

  } else {
    key = `$${key}`
    Vue[key] = proto
    Vue.prototype[key] = proto
  }
}

/**
  * Sequential ID generator
  */
export let id = (i => () => i++)(0)

/**
  * Splits space/tab separated string into array and cleans empty string items.
  */
export let split = value => {
  if (typeof value !== 'string') return []
  return value.split(/\s+/gi).filter(v => v)
}

/**
  * Cleanes and transforms string of format "x y" into object {x, y}.
  * Possible combinations:
  *   x - left, center, right
  *   y - top, bottom
  */
export let listToDirection = value => {

  let x = null, y = null, directions = {
    x: ['left', 'center', 'right'],
    y: ['top', 'bottom']
  }

  if (typeof value === 'string') {
    value = split(value)
  }

  value.forEach(v => {
    if (directions.y.indexOf(v) !== -1) y = v
    if (directions.x.indexOf(v) !== -1) x = v
  })

  return { x, y }
}
